import db from '@/databases/indexedDB';
import { httpGet } from '@/services/http';

// Function to transfer JSON to the database
async function getRequestsData() {
    try {
        // Retrieve the most recent update timestamp from the 'requests' store
        const mostRecentUpdate = await db.requests.orderBy('updatedAt').last();

        // Prepare the query parameter
        const updatedAt = mostRecentUpdate ? mostRecentUpdate.updatedAt : null;
        const queryParams = updatedAt ? `?updated_at=${updatedAt}` : '';

        // Fetch updated data from the server
        const jsonData = await httpGet(`/api/v1/administrators/requests${queryParams}`);

        console.log('Max. requests: ' + jsonData.data.data.length)

        // Use a transaction for all writes to ensure data integrity
        await db.transaction('rw', [db.users, db.contact_numbers, db.requests, db.details, db.types], async () => {
            // Iterate over the data
            for (const item of jsonData.data.data) {
                // Handle user and admin details
                const userId = await db.users.put({
                    id: item.user.uuid,
                    firstName: item.user.first_name,
                    lastName: item.user.last_name,
                    emailAddress: item.user.email_address,
                    imagePath: item.user.image_path,
                    emailVerifiedAt: item.user.email_verified_at,
                    createdAt: item.user.created_at,
                    updatedAt: item.user.updated_at,
                    deletedAt: item.user.deleted_at,
                    role: item.user.role.role_name,
                    status: item.user.status.status_name
                });

                const adminId = item.admin ? await db.users.put({
                    id: item.admin.uuid,
                    firstName: item.admin.first_name,
                    lastName: item.admin.last_name,
                    emailAddress: item.admin.email_address,
                    imagePath: item.admin.image_path,
                    emailVerifiedAt: item.admin.email_verified_at,
                    createdAt: item.admin.created_at,
                    updatedAt: item.admin.updated_at,
                    deletedAt: item.admin.deleted_at,
                    role: item.admin.role.role_name,
                    status: item.admin.status.status_name
                }) : null;

                // Handle requests
                const requestId = await db.requests.put({
                    id: item.uuid,
                    status: item.status,
                    remarks: item.remarks,
                    createdAt: item.created_at,
                    updatedAt: item.updated_at,
                    deletedAt: item.deleted_at,
                    userId: userId,
                    adminId: adminId
                });

                // Add contact numbers
                for (const contact of item.user.contact_numbers) {
                    await db.contact_numbers.put({
                        id: contact.uuid,  // Using UUID as ID for contact numbers
                        countryCode: contact.country_code.country_code,
                        contactNumber: contact.contact_number,
                        country: contact.country_code.country.country,
                        is_primary: contact.is_primary,
                        createdAt: contact.created_at,
                        updatedAt: contact.updated_at,
                        deletedAt: contact.deleted_at,
                        userId: userId  // Linking contact number to user
                    });
                }

                if (item.admin) {
                    // Add contact numbers
                    for (const contact of item.admin.contact_numbers) {
                        await db.contact_numbers.put({
                            id: contact.uuid,  // Using UUID as ID for contact numbers
                            countryCode: contact.country_code.country_code,
                            contactNumber: contact.contact_number,
                            country: contact.country_code.country.country,
                            is_primary: contact.is_primary,
                            createdAt: contact.created_at,
                            updatedAt: contact.updated_at,
                            deletedAt: contact.deleted_at,
                            userId: userId  // Linking contact number to user
                        });
                    }
                }

                // Add details and types
                for (const detail of item.details) {
                    const typeId = await db.types.put({
                        id: detail.type.uuid,  // Using UUID as ID for types
                        type: detail.type.type,
                        fieldName: detail.type.field_name,
                        isProveRequired: detail.type.is_prove_required,
                        userRoleId: detail.type.role.role_name,
                        createdAt: detail.type.created_at,
                        updatedAt: detail.type.updated_at,
                        deletedAt: detail.type.deleted_at
                    });

                    await db.details.put({
                        id: detail.uuid,  // Using UUID as ID for details
                        value: detail.value,
                        filePath: detail.file_path,
                        originalName: detail.original_name,
                        createdAt: detail.created_at,
                        updatedAt: detail.updated_at,
                        deletedAt: detail.deleted_at,
                        typeId: typeId,  // Linking detail to type
                        requestId: requestId   // Linking detail to user
                    });
                }
            }
        });

        console.log("Data has been successfully transferred to the database.");
    } catch (err) {
        console.error("Failed to populate database:", err);
    }
}

const deleteDatabase = () => {
    const request = indexedDB.deleteDatabase('rectrix-link-admin');

    request.onsuccess = function () {
        console.log(`Database deleted successfully`);
    };

    request.onerror = function () {
        console.error(`Error deleting database`);
    };

    request.onblocked = function () {
        console.warn(`Delete operation blocked for database`);
    };
};


export const indexedDBService = {
    getRequestsData,
    deleteDatabase
};