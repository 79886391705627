import { defineStore } from "pinia";
import Cookies from 'js-cookie';
// Toast Notification
// In any Vue component, import the toast function
import { toast } from 'vue3-toastify';
import { httpGet, httpPost } from "@/services/http";
import { getToken } from 'firebase/messaging';
import { useWebSocketStore } from '@/stores/WebsocketStore'
import { indexedDBService } from "@/services/indexedDBService";

export const useAuthStore = defineStore("AuthStore", {
    // State
    state: () => {
        return {
            isLogin: !!Cookies.get('access_token'),
            accessToken: Cookies.get('access_token') ?? '',
            refreshToken: Cookies.get('refresh_token') ?? '',
            profile: localStorage.profile ? JSON.parse(localStorage.profile) : {},
            fcmToken: null,
            notificationListenerAdded: false,
        }
    },
    // Actions
    actions: {
        async initializeNotificationPermissions(getProfile = true) {
            if (getProfile) {
                await this.getProfile();
            }
            if (!this.notificationListenerAdded && 'permissions' in navigator) {
                navigator.permissions.query({ name: 'notifications' }).then((notificationPerm) => {
                    notificationPerm.onchange = () => {
                        console.log("User changed notification settings. New permission: " + notificationPerm.state);
                        this.updateDeviceInfo();
                    };
                });
                this.notificationListenerAdded = true; // Set the flag to true after adding the listener
            }
        },
        async setTokens(accessToken, refreshToken) {
            this.accessToken = accessToken;
            this.refreshToken = refreshToken;
            this.isLogin = true;
            Cookies.set('access_token', accessToken, { secure: true, sameSite: 'Strict' });
            Cookies.set('refresh_token', refreshToken, { secure: true, sameSite: 'Strict' });
            await this.getProfile();
            this.updateDeviceInfo();
        },
        async logout(isForce = false) {
            if (navigator.onLine) {
                try {
                    if (!isForce) {
                        await httpGet('/api/v1/administrators/logout');
                    }
                    Cookies.remove('access_token');
                    Cookies.remove('refresh_token');
                    this.isLogin = false;
                    this.accessToken = '';
                    this.refreshToken = '';
                    this.profile = {};
                    const webSocketStore = useWebSocketStore();
                    webSocketStore.disconnect();
                    indexedDBService.deleteDatabase();
                    localStorage.clear();
                    toast.success('Logout successfully');
                    this.$router.push('/login'); // Use the router instance passed as a parameter
                } catch (error) {
                    console.error('Error logging out:', error);
                    toast.error('Logout failed. Please try again.');
                }
            } else {
                toast.warning('No internet connection. Logout ignored.');
            }
        },
        getProfile() {
            console.log('Getting profile');
            if (this.isLogin) {
                return httpGet('/api/v1/administrators/profiles')
                    .then(result => {
                        this.profile = result.data.data;
                        localStorage.profile = JSON.stringify(this.profile);
                        if (this.profile.email_verified_at === null) {
                            this.$router.push('/email-verification');
                        } else if (this.profile.status && this.profile.status.status_slug !== 'active') {
                            this.$router.push('/account-inactive');
                        } else if (this.$router.currentRoute.value.path === '/account-inactive' && this.profile.status.status_slug === 'active') {
                            this.$router.push('/');
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
        },
        checkNotificationAPISupported() {
            return 'Notification' in window &&
                'serviceWorker' in navigator &&
                'PushManager' in window;
        },

        updateDeviceInfo() {
            if (this.checkNotificationAPISupported()) {
                Notification.requestPermission()
                    .then((permission) => {
                        if (permission === 'granted') {
                            console.log('Notification permission granted.');
                            if (this.fcmToken) {
                                return this.fcmToken;
                            } else {
                                return getToken(this.$messaging, { vapidKey: 'BJLvpQH73FI5FByf7Pgdol1s-LyEykuHJ50Xo0beSd1vu52DU3MpPxORwdL8_9nkXz06dOEzfefY1H3nbJAwkYI' });
                            }
                        } else {
                            console.log('Unable to get permission to notify.');
                            return null;
                        }
                    })
                    .then((token) => {
                        if (token) {
                            this.fcmToken = token;
                            console.log('FCM Token:', token);
                        }
                        this.sendDeviceInfo(token);
                    })
                    .catch((error) => {
                        console.error('Error during notification permission request or token retrieval:', error);
                    });
            } else {
                console.log('Notifications are not supported in this browser.');
                this.sendDeviceInfo();
            }
        },
        sendDeviceInfo(token = null) {
            let info = this.getDeviceInfo();
            let data = new FormData();
            if (token) {
                data.append('fcm_token', token);
            }
            data.append('device_type', info.platform);
            data.append('browser_infomation', info.browserName);
            data.append('language', info.language);
            data.append('time_zone', info.timeZone);

            const config = {
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
            };

            try {
                httpPost('/api/v1/administrators/devices/update', data, null, config)
                    .catch((error) => {
                        console.log(error);
                    });
                console.log('Device info sent successfully');
            } catch (error) {
                console.error('Failed to update device info', error);
            }
        },
        getDeviceInfo() {
            var deviceInfo = {
                platform: navigator.userAgentData ? navigator.userAgentData.platform : navigator.platform,
                userAgent: navigator.userAgent,
                appVersion: navigator.appVersion,
                appName: navigator.appName,
                appCodeName: navigator.appCodeName,
                vendor: navigator.vendor,
                language: navigator.language,
                languages: navigator.languages,
                cookieEnabled: navigator.cookieEnabled,
                doNotTrack: navigator.doNotTrack,
                hardwareConcurrency: navigator.hardwareConcurrency,
                maxTouchPoints: navigator.maxTouchPoints,
                online: navigator.onLine,
                screenWidth: window.screen.width,
                screenHeight: window.screen.height,
                colorDepth: window.screen.colorDepth,
                deviceMemory: navigator.deviceMemory || 'N/A',
                browserName: this.getBrowserName(),
                viewportWidth: window.innerWidth,
                viewportHeight: window.innerHeight,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            };
            return deviceInfo;
        },
        getBrowserName() {
            var userAgent = navigator.userAgent, temp;
            var match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(match[1])) {
                temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
                return 'IE ' + (temp[1] || '');
            }
            if (match[1] === 'Chrome') {
                temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
                if (temp != null) return temp.slice(1).join(' ').replace('OPR', 'Opera');
            }
            match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((temp = userAgent.match(/version\/(\d+)/i)) != null) match.splice(1, 1, temp[1]);
            return match.join(' ');
        },
    }
});
