<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Request Details</h1>
                <p v-if="data && data.user && data.user.role">{{ data.user.role }} - {{ data.user.firstName }} {{
                    data.user.lastName }}</p>
            </div>

            <v-row>
                <v-col cols="12" sm="6" class="pa-0">
                    <div class="info-container container">
                        <v-container v-if="data" v-for="(detail, index) in data.details" :key="index">
                            <h3>{{ /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{6}$/.test(detail.value) ?
                                this.$moment(detail.value).format('DD MMM yyyy') : detail.value }}</h3>
                            <p>{{ detail.type.type }}</p>
                            <p>Prove Required: {{ detail.type.isProveRequired === 1 ? 'Yes' : 'No' }}</p>
                            <div class="buttons">
                                <v-btn v-if="detail.type.isProveRequired" class="button-1"
                                    @click.prevent="loadFile(detail.id)">View Prove</v-btn>
                            </div>
                        </v-container>
                        <v-container v-else>
                            Loading...
                        </v-container>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" class="prove-viewer pa-0">
                    <h3>Prove Viewer</h3>
                    <div class="controls" v-if="fileUrl && !isPdf">
                        <v-btn @click="zoomIn" prepend-icon="mdi-magnify-plus-outline" class="mr-4">Zoom In</v-btn>
                        <v-btn @click="zoomOut" prepend-icon="mdi-magnify-minus-outline">Zoom Out</v-btn>
                    </div>
                    <iframe v-if="fileUrl" :src="fileUrl" ref="iframe" width="100%" height="650px"
                        frameBorder="0"></iframe>
                    <p v-else>Click 'View Prove' to show file</p>
                    <a v-if="fileUrl" :href="fileUrl" target="_blank" class="button-3">Load in new tab</a>
                </v-col>
            </v-row>

            <div class="id">
                <p>Request ID: {{ id }}</p>
            </div>

            <v-textarea label="Remarks" v-model="remarks" v-if="data && data.status === 'Pending'"></v-textarea>

            <p v-else-if="data">Remarks: {{ data.remarks ?? '-' }}</p>

            <div class="buttons" v-if="data && data.status === 'Pending'">
                <v-btn class="mr-4" color="green darken-1" prepend-icon="mdi-check-circle" :loading="isApprovedLoading"
                    :disabled="isApprovedDisabled" @click.prevent="submit('Approved')">
                    Approved
                </v-btn>
                <v-btn color="red darken-1" prepend-icon="mdi-close-circle" :loading="isRejectedLoading"
                    :disabled="isRejectedDisabled" @click.prevent="submit('Rejected')">
                    Rejected
                </v-btn>
            </div>

            <p v-else-if="data && data.status">Status: {{ data.status }}</p>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import { toast } from 'vue3-toastify';
import { httpGet, httpPost } from '@/services/http';
import db from '@/databases/indexedDB';
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default {
    name: 'RequestDetailsView',
    components: {
        TopNav,
    },
    data() {
        return {
            id: this.$route.params.id,
            data: null,
            fileUrl: null,
            zoom: 1,
            isPdf: false, // Flag to check if the file is a PDF
            isApprovedLoading: false,
            isRejectedLoading: false,
            isApprovedDisabled: false,
            isRejectedDisabled: false,
            remarks: '',
        };
    },
    created() {
        indexedDBService.getRequestsData();
        this.fetchData();
    },
    beforeUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        fetchData() {
            const observableData = liveQuery(async () => {
                try {
                    // Fetch the single request record
                    const request = await db.requests.get({ id: this.id });

                    if (request) {
                        // Fetch user associated with the request
                        const user = await db.users.get({ id: request.userId });
                        const admin = request.adminId ? await db.users.get({ id: request.adminId }) : null;

                        // Fetch contact numbers for user and admin
                        const userContacts = await db.contact_numbers.where({ userId: user.id }).toArray();
                        const adminContacts = request.adminId ? await db.contact_numbers.where({ userId: admin.id }).toArray() : null;

                        // Fetch details for user and enhance with type data
                        const requestDetails = await db.details.where({ requestId: request.id }).toArray();
                        for (let detail of requestDetails) {
                            detail.type = await db.types.get({ id: detail.typeId });
                        }

                        // Return the enhanced data
                        return {
                            ...request,
                            user: {
                                ...user,
                                contactNumbers: userContacts,
                            },
                            admin: admin ? {
                                ...admin,
                                contactNumbers: adminContacts,
                            } : null,
                            details: requestDetails,
                        };
                    }

                    // Return null or an empty object if the request does not exist
                    return null;
                } catch (error) {
                    console.error('Failed to fetch data:', error);
                    return null;
                }
            });

            observableData.subscribe((result) => {
                this.data = result;
            });
        },
        async loadFile(id) {
            try {
                const response = await httpGet(`/api/v1/administrators/requests/files/${id}`, {
                    responseType: 'arraybuffer'
                });
                if (response.data) {
                    const contentType = response.headers['content-type'];
                    const blob = new Blob([response.data], { type: contentType });
                    const url = URL.createObjectURL(blob);
                    this.isPdf = contentType === 'application/pdf';
                    this.fileUrl = url; // Set the iframe src to this URL
                } else {
                    throw new Error('No data received');
                }
            } catch (error) {
                console.error('Failed to load file:', error);
                // Handle error if needed
                this.fileUrl = null;
            }
        },
        zoomIn() {
            this.zoom += 0.1;
            this.applyZoom();
        },
        zoomOut() {
            if (this.zoom > 0.1) {
                this.zoom -= 0.1;
                this.applyZoom();
            }
        },
        applyZoom() {
            if (!this.isPdf) {
                const iframe = this.$refs.iframe;
                if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
                    const iframeDoc = iframe.contentWindow.document;
                    const styleTag = iframeDoc.getElementById('zoom-style');
                    const zoomStyle = `body { transform: scale(${this.zoom}); transform-origin: top left; }`;
                    if (styleTag) {
                        styleTag.innerHTML = zoomStyle;
                    } else {
                        const styleElement = iframeDoc.createElement('style');
                        styleElement.id = 'zoom-style';
                        styleElement.innerHTML = zoomStyle;
                        iframeDoc.head.appendChild(styleElement);
                    }
                }
            }
        },
        async submit(type) {
            let dataToSend = {
                id: this.id,
                type,
                remarks: this.remarks,
            };
            let id = toast.loading('Please wait...');
            this.isApprovedLoading = type === 'Approved';
            this.isRejectedLoading = type === 'Rejected';
            this.isApprovedDisabled = type === 'Rejected';
            this.isRejectedDisabled = type === 'Approved';

            try {
                const response = await httpPost('/api/v1/administrators/requests/response', dataToSend, id);
                toast.update(id, {
                    render: response.data.message,
                    type: "success",
                    isLoading: false,
                    autoClose: 3000
                });
                indexedDBService.getRequestsData();
                this.$router.push('/');
            } catch (error) {
                console.error(error);
            } finally {
                this.isApprovedLoading = false;
                this.isRejectedLoading = false;
                this.isApprovedDisabled = false;
                this.isRejectedDisabled = false;
            }
        },
    },
    watch: {
        fileUrl(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.applyZoom();
                });
            }
        }
    }
};
</script>

<style src="@/assets/css/job.css" scoped></style>
<style src="@/assets/css/index.css" scoped></style>
<style scoped>
form {
    width: 60%;
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 5px;
    text-align: unset;
    white-space: pre-line;
}

.info-container h3 {
    color: var(--primary-blue);
}

.prove-viewer {
    text-align: center;
}

.prove-viewer *:nth-child(2) {
    margin: 10px auto;
}
</style>
